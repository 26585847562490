<script setup>
import { NuxtLink } from '#components';
const router = useRouter()
const emit = defineEmits(['click'])
const prop = defineProps({
  to        : { type: String, default: null },
  type      : { type: String, default: 'button' },
  color     : { type: [String, Boolean], default: 'primary' },
  loading   : { type: Boolean, default: false },
  block     : { type: Boolean, default: false },
  circle    : { type: Boolean, default: false },
  outlined  : { type: Boolean, default: false },
})

const colorTypeClass = computed(() => {
  if (prop.color) {
    switch(prop.color) {
      case 'primary'  : return 'btn-indigo';
      case 'secondary': return 'btn-slate';
      case 'success'  : return 'btn-green';
      case 'info'     : return 'btn-sky';
      case 'warning'  : return 'btn-amber';
      case 'error'    : return 'btn-red';
      default: return `btn-${prop.color}`;
    }
  } else {
    return `btn-zinc`;
  }
})


</script>

<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :to="to"
    :type="to ? null : type"
    class="btn"
    :class="[
      colorTypeClass,
      {
        'btn-block'   : block,
        'btn-circle'  : circle,
        'btn-outlined': outlined,
      }
    ]"
    @click="emit('click', $event)"
  >
    <Icon
      v-if="loading"
      icon="mdi:loading"
      width="24"
      class="animate-spin mx-auto"
    />
    <slot v-else></slot>
  </component>
</template>